import { makeStyles } from '@mui/styles';
import { display } from '@mui/system';
import theme from 'src/style-system/theme';

export const useStyles = makeStyles({
  mainContainer: {
    padding: '10px 20px 100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',

    [theme.breakpoints.up('sm')]: {
      //padding: '120px 25px 190px',
    },

    [theme.breakpoints.up(800)]: {
      padding: '40px 50px 65px',
    },
    [theme.breakpoints.up(1300)]: {
      padding: '140px 50px 140px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '140px 85px 140px',
    },
  },

  titleWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',

    [theme.breakpoints.down(1300)]: {
      maxWidth: '450px',
    },
    [theme.breakpoints.down(800)]: {
      maxWidth: '350px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '310px',
    },
    [theme.breakpoints.down(500)]: {
      maxWidth: '280px',
    },
  },

  priceTagWrapper: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',

    [theme.breakpoints.up(800)]: {
      right: '0px',
      bottom: '-100px',
      top: 'auto',
    },

    [theme.breakpoints.up(1100)]: {
      right: '-125px',
      bottom: '80px',
    },
  },

  priceTag: {
    [theme.breakpoints.down('lg')]: {
      transform: 'scale(0.8)',
    },
    [theme.breakpoints.down('md')]: {
      transform: 'scale(0.7)',
    },
  },

  priceInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    inset: '0 0 0 0',
  },

  button: {
    width: '200px !important',
    height: '48px !important',
    borderRadius: '24px !important',

    [theme.breakpoints.up('sm')]: {
      width: '300px !important',
    },

    [theme.breakpoints.up(1300)]: {
      width: '425px !important',
      height: '76px !important',
      borderRadius: '55px !important',
    },
  },
});
