import { useState, useEffect, memo } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Container, Input } from '@mui/material';
import Link from 'next/link';
import { useMutation, useReactiveVar } from '@apollo/client';
import { JOIN_THE_CLUB_MUTATION } from '@graphql/mutation/joinTheClub';

import theme from 'src/style-system/theme';
import {
  footerList,
  baseFooterList,
  socialMedia,
  AFFILIATE_PROGRAM_LINK,
} from '@utils/data';
import { validateEmail } from '@utils/validator';
import useAuth from '@utils/useAuth';
import { AppRoutes } from '@utils/appRoutes';
import { snackbarInfo, utmQueryVar } from 'store/utils';
import TCCLogo from '@components/TCCLogo';

import { useStyles } from './useStyles';
import { useRouter } from 'next/router';

const Footer = () => {
  const router = useRouter();

  const { detectedCountryCode } = useAuth();

  const classes = useStyles();

  useEffect(() => {
    return () => {
      snackbarInfo(null);
    };
  }, [snackbarInfo]);

  const handleClick = () => {
    router.push(`/${detectedCountryCode}${AppRoutes.ORDER_STEP_TWO}`);
  };

  return (
    <>
      <Box bgcolor="garamMasala.main" marginTop="-1px">
        <Container maxWidth="xl" disableGutters>
          <Box className={classes.mainContainer}>
            <Box className={classes.logoWrapper}>
              <TCCLogo />
            </Box>
            <Box className={classes.topContainer}>
              <Box
                sx={{
                  [theme.breakpoints.up('md')]: {
                    display: 'flex',
                    columnGap: '15px',
                  },
                }}
              >
                <Box>
                  <Grid container>
                    {footerList.map((item) => {
                      return (
                        <Grid
                          item
                          xs={6}
                          sx={{
                            color: 'turmeric.main',
                            '&:hover': {
                              color: 'turmeric.dark',
                            },
                          }}
                          key={item.id}
                        >
                          {item.link === AppRoutes.ORDER_STEP_ONE ? (
                            <Typography
                              variant="body2"
                              align="left"
                              sx={{
                                marginBottom: '24px',
                                cursor: 'pointer',
                              }}
                              onClick={handleClick}
                            >
                              {item.value}
                            </Typography>
                          ) : (
                            <Link href={item.link}>
                              <Typography
                                variant="body2"
                                align="left"
                                sx={{ marginBottom: '24px', cursor: 'pointer' }}
                              >
                                {item.value}
                              </Typography>
                            </Link>
                          )}
                        </Grid>
                      );
                    })}
                    <a href={AFFILIATE_PROGRAM_LINK} target="_blank">
                      <Typography
                        variant="body2"
                        align="left"
                        color="turmeric.main"
                        sx={{
                          marginBottom: '24px',
                          cursor: 'pointer',
                          '&:hover': {
                            color: 'turmeric.dark',
                          },
                        }}
                      >
                        Affiliate Program
                      </Typography>
                    </a>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      display: 'none',
                    },
                  }}
                >
                  <Grid container>
                    {baseFooterList.map((item) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            color: 'turmeric.main',
                            '&:hover': {
                              color: 'turmeric.dark',
                            },
                          }}
                          key={item.id}
                        >
                          <Link href={item.link}>
                            <Typography
                              variant="body2"
                              align="left"
                              sx={{ marginBottom: '24px', cursor: 'pointer' }}
                            >
                              {item.value}
                            </Typography>
                          </Link>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Box>
              <Box className={classes.joinClubWrapper}>
                <Box>
                  <div className={`klaviyo-form-SHFrjV`}></div>
                  <Box className={classes.socialMediaiconWrapper}>
                    {socialMedia.map((item) => (
                      <a target="_blank" href={item.href} key={item.id}>
                        <item.Icon />
                      </a>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box className={classes.bottomContainer}>
        {baseFooterList.map((item) => {
          return (
            <Link href={item.link} key={item.id}>
              <Typography variant="caption" color="cinnamon.main" key={item.id}>
                {item.value}
              </Typography>
            </Link>
          );
        })}
      </Box>
    </>
  );
};

export default memo(Footer);
