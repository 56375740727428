import { useRef } from 'react';
import Image from 'next/image';
import { Box, Typography } from '@mui/material';
import { usePrismicDocumentByUID } from '@prismicio/react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import useStyles from './useStyles';

const InstagramSection = () => {
  const ref = useRef<any>(null);

  const classes = useStyles();

  const [data, { state }] = usePrismicDocumentByUID(
    'home_page_instagram_grid',
    'instagram_post'
  );

  const { slices } = data?.data || {};
  const sliceData = slices?.length ? slices[0] : null;
  const { items } = sliceData || {};

  const translateForward = () => {
    if (ref?.current) {
      ref.current.scrollLeft += 250;
      ref.current.style.scrollBehavior = 'smooth';
    }
  };

  const translateBackward = () => {
    if (ref?.current) {
      ref.current.scrollLeft -= 250;
      ref.current.style.scrollBehavior = 'smooth';
    }
  };

  return state === 'loaded' ? (
    <Box className={classes.maincontainer}>
      <Box className={classes.container}>
        <Typography variant="h1" color="whitePepper.main" align="center">
          On the Grid
        </Typography>
        <Box className={classes.backwardBtnWrapper}>
          <ArrowBackIosNewIcon
            fontSize="large"
            sx={{ color: 'garamMasala.main', cursor: 'pointer' }}
            onClick={translateForward}
          />
        </Box>

        <Box ref={ref} className={classes.itemscontainer}>
          {items?.map((item: any) => {
            const { instagram_post_img, instagram_post_link } = item || {};
            return (
              <a target="_blank" href={instagram_post_link.url}>
                <Box className={classes.image}>
                  <Image
                    src={instagram_post_img.url}
                    alt={instagram_post_img?.alt || 'Instagram_Post'}
                    objectFit="contain"
                    layout="fill"
                  />
                </Box>
              </a>
            );
          })}
        </Box>
        <Box className={classes.forwardBtnWrapper}>
          <ArrowForwardIosIcon
            fontSize="large"
            sx={{ color: 'garamMasala.main', cursor: 'pointer' }}
            onClick={translateBackward}
          />
        </Box>
      </Box>
    </Box>
  ) : null;
};

export default InstagramSection;
