import {
  CarouselType,
  NextImageSrc,
  reviewCarouselType,
  THomePageGiftSection,
  TOneLineDeatil,
  TTasteSection,
} from '@utils/types';
import { ButtonColorVariant } from '@components/Button';
import {
  AL00_POSTO_WITH_TAG,
  AVS_LOGO,
  BROWN_GIRL_MAGAZINE_LOGO,
  CHEF_ASSET,
  DELIVERY_RANK_LOGO,
  DIWALI_GIFT_BOX,
  DIWALI_PARTY_BOX,
  FILLED_GIFT_CARD,
  GIFT_BOX_STRAIGHT,
  GINGER_CHILLI,
  GROUPING_BAG,
  HOLIDAY_GIFT_BOX_IMAGE,
  MILIJULI,
  NEW_GIFT_BOX_STRAIGHT,
  NEW_GIFT_CARD_TWO,
  PANEER_BUTTER_MASALA_WITH_TAG,
  PAV_BHAJI_PACKET,
  PAV_BHAJI_WITH_TAG,
  THE_BETTER_INDIA_LOGO,
  THREE_D_PHONE,
  VEN_PONGAL_WITH_TAG,
  YOUR_STORY_LOGO,
} from '@utils/data';
import { AppRoutes } from '@utils/appRoutes';
import BuzzFeedLogo from '@public/assets/png/BuzzFeedLogo.png';
import NewYorkPostLogo from '@public/assets/png/NewYorkPostLogo.png';
import BonAppeetitLogo from '@public/assets/png/BonAppeetitLogo.png';
import WomenHealthLogo from '@public/assets/png/WomenHealthLogo.png';
import TheTakeoutLogo from '@public/assets/png/TheTakeoutLogo.png';
import howToPrepageImg1 from '@public/assets/png/howToPrepageImg1.png';
import howToPrepageImg2 from '@public/assets/png/howToPrepageImg2.png';
import howToPrepageImg3 from '@public/assets/png/howToPrepageImg3.png';

const akhilaReview = 'https://d222i9ppxs2fqe.cloudfront.net/akhilaReview.png';
const akhilaReviewBlur =
  'https://d222i9ppxs2fqe.cloudfront.net/akhilaReview_blur.png';
const reviewPic = 'https://d222i9ppxs2fqe.cloudfront.net/desktopReviewPic.png';
const reviewPicBlur =
  'https://d222i9ppxs2fqe.cloudfront.net/desktopReviewPic_blur.png';
const urjaReview = 'https://d222i9ppxs2fqe.cloudfront.net/urja_review.png';

const posterContent: CarouselType[] = [
  {
    id: '1',
    icon: THREE_D_PHONE,
    alt: 'THREE_D_PHONE',
    title: 'Pick your plan and meals',
    subtitle:
      'Customize your meal plan with authentic vegetarian dishes prepared with all-natural ingredients.',
    //blurUrl: paneerButterMasalaBagBlur,
  },
  {
    id: '2',
    icon: GROUPING_BAG,
    alt: 'GROUPING_BAG',
    width: 347,
    height: 259,
    title: 'Receive your delivery',
    subtitle:
      'Get your meals delivered right to your doorstep on your schedule for no fee!',
    //blurUrl: stainlessSteelPotBlur,
  },
  {
    id: '3',
    icon: MILIJULI,
    alt: 'MILIJULI',
    width: 218,
    height: 217,
    title: 'Enjoy in 5 minutes!',
    subtitle:
      'Just add hot water, wait a few minutes, and your favorite dish is ready to eat!',
    //blurUrl: hyderabadiBiryaniBlur,
  },
];

const carouselContent: CarouselType[] = [
  {
    id: '1',
    icon: GINGER_CHILLI,
    alt: 'GINGER_CHILLI',
    title: 'Cuisines of India, not Indian cuisine',
    subtitle:
      'All our dishes use high quality ingredients and spices specific to each region of India. You won’t catch us mixing our Kashmiri chillies with our byadagis!',
  },
  {
    id: '2',
    icon: CHEF_ASSET,
    alt: 'CHEF_ASSET',
    title: 'We bring true local flavor',
    subtitle:
      'Our team of chefs hail from all over India and bring their expertise into creating their regional dishes with that true authentic flavor.',
  },
  {
    id: '3',
    icon: PAV_BHAJI_PACKET,
    alt: 'PAV_BHAJI_PACKET',
    title: 'When we say clean food, we mean it',
    subtitle:
      'We don’t do preservatives, period. Our innovative freeze drying technology gives our meals a long shelf life and retains every ounce of flavor and nutrition.',
  },
];

const TasteSectionContent: TTasteSection[] = [
  {
    id: 1,
    title: 'A Taste of Delhi',
    city: 'Delhi',
    description:
      'Delhi brings together a blend of North Indian flavors touched with a distinctly Mughalai twist and finished with something that is entirely the city’s very own. Rich, creamy, and hearty dishes from the capital city are sure to make you feel like royalty!',
    icon: PANEER_BUTTER_MASALA_WITH_TAG,
    alt: 'PANEER_BUTTER_MASALA_WITH_TAG',
    bgColor: 'turmeric.main',
    titleColor: 'garamMasala.main',
    textColor: 'dark3.main',
    btnTextColor: 'whitePepper.main',
    btnColor: ButtonColorVariant.CINNAMON,
    btnHoveredColor: 'cinnamon.light',
    buttonLabel: 'Try Paneer Butter Masala',
  },
  
  {
    id: 2,
    title: 'A Taste of Kolkata',
    city: 'Kolkata',
    description:
      'The cuisine of Kolkata reflects its myriad history and many cultural influences, ranging from simple two-spice home fare and humble street food to sumptuous vegetable medleys and delectable milk sweets.',
    icon: AL00_POSTO_WITH_TAG,
    alt: 'AL00_POSTO_WITH_TAG',
    bgColor: 'cinnamon.main',
    titleColor: 'whitePepper.main',
    textColor: 'light1.main',
    btnTextColor: 'garamMasala.main',
    btnColor: ButtonColorVariant.TURMERIC,
    btnHoveredColor: 'turmeric.light',
    buttonLabel: 'Try Aloo Posto',
  },
  {
    id: 3,
    title: 'A Taste of Chennai',
    city: 'Chennai',
    description:
      'It is said that the people of South India can cook rice and lentils in a thousand different ways, no two the same. Explore a medley of warming spices, tangy flavors, and robust textures that are sure to delight your taste buds!',
    icon: VEN_PONGAL_WITH_TAG,
    alt: 'VEN_PONGAL_WITH_TAG',
    bgColor: 'garamMasala.main',
    titleColor: 'whitePepper.main',
    textColor: 'light1.main',
    btnTextColor: 'garamMasala.main',
    btnColor: ButtonColorVariant.TURMERIC,
    btnHoveredColor: 'turmeric.light',
    buttonLabel: 'Try Ven Pongal',
  },
  {
    id: 4,
    title: 'A Taste of Mumbai',
    city: 'Mumbai',
    description:
      'Mumbai’s cuisine ranges from simple comfort foods to vibrant street fare to exquisite gravies, but the traits they share reflect the attitudes of the city itself: bold, unapologetic, and bursting with flavor.',
    icon: PAV_BHAJI_WITH_TAG,
    alt: 'PAV_BHAJI_WITH_TAG',
    bgColor: 'whitePepper.main',
    titleColor: 'garamMasala.main',
    textColor: 'dark3.main',
    btnTextColor: 'whitePepper.main',
    btnColor: ButtonColorVariant.CINNAMON,
    btnHoveredColor: 'cinnamon.light',
    buttonLabel: 'Try Pav Bhaji',
  },
  
];

const ReviewCarouselContent: reviewCarouselType[] = [
  {
    id: 1,
    title: `“This saves me so much time. Your paneer butter masala was approved by my one-year-old! Win-win for mommy!”`,
    icon: urjaReview,
    alt: 'urjaReviewBlur',
    blurUrl: akhilaReviewBlur,
    author: 'Urga S.B',
  },
  {
    id: 2,
    title: `“Amma may live far away, but now I can have home cooked meals every night with The Cumin Club.”`,
    icon: akhilaReview,
    alt: 'akhilaReview',
    blurUrl: akhilaReviewBlur,
    author: 'Akhila G.',
  },
  {
    id: 3,
    title: `“Ever since I started eating the Cumin Club meals, I haven't stopped! I love this food so much!”`,
    icon: reviewPic,
    alt: 'reviewPic',
    blurUrl: reviewPicBlur,
    author: 'Cari W.',
  },
];

const GiftSectionContent: THomePageGiftSection[] = [
  {
    id: 1,
    img: GIFT_BOX_STRAIGHT,
    alt: 'GIFT BOX',
    btnText: 'Gift Box',
    routes: AppRoutes.GIFT_BOX_STEP_ONE,
    //routes: 'https://get.thecuminclub.com/holiday-gift-box/',
    width: 190,
    height: 260,
  },
  {
    id: 2,
    img: FILLED_GIFT_CARD,
    alt: 'GIFT CARD',
    btnText: 'Gift Card',
    //routes: AppRoutes.GIFT_CARD_STEP_ONE,
    routes: AppRoutes.GIFT,
    width: 241,
    height: 225,
  },
];

//TODO:later remove after diwali
export const DiwaliGiftSectionContent: THomePageGiftSection[] = [
  {
    id: 1,
    img: DIWALI_PARTY_BOX,
    alt: 'PARTY BOX',
    btnText: 'Dinner Party Box',
    routes: AppRoutes.GIFT_CARD_STEP_ONE,
  },
  {
    id: 2,
    img: DIWALI_GIFT_BOX,
    alt: 'GIFT BOX',
    btnText: 'Gift Box',
    routes: AppRoutes.GIFT_CARD_STEP_ONE,
  },
];

const LogoSectionContent: {
  id: number;
  icon: NextImageSrc;
  alt: string;
  width: number;
  height: number;
  link: string;
}[] = [
  {
    id: 1,
    icon: YOUR_STORY_LOGO,
    alt: 'YOUR_STORY_LOGO',
    width: 236,
    height: 92,
    link: 'https://yourstory.com/2019/10/expat-indian-cuisine-us-cumin-club-food-startup',
  },
  {
    id: 2,
    icon: BROWN_GIRL_MAGAZINE_LOGO,
    alt: 'BROWN_GIRL_MAGAZINE_LOGO',
    width: 190,
    height: 160,
    link: 'https://browngirlmagazine.com/if-youre-missing-homestyle-indian-food-cumin-club-should-your-weekly-meal-plan/',
  },
  {
    id: 3,
    icon: DELIVERY_RANK_LOGO,
    alt: 'DELIVERY_RANK_LOGO',
    width: 230,
    height: 55,
    link: 'https://www.deliveryrank.com/blog/cuminclub-interview',
  },
  {
    id: 4,
    icon: THE_BETTER_INDIA_LOGO,
    alt: 'THE_BETTER_INDIA_LOGO',
    width: 133,
    height: 133,
    link: 'https://www.thebetterindia.com/285841/nri-creates-healthy-vegetarian-five-minute-meal-kits-indian-food-in-us/',
  },
  {
    id: 5,
    icon: AVS_LOGO,
    alt: 'AVS_LOGO',
    width: 150,
    height: 150,
    link: 'https://www.avstv.com/cumin-club-celebrates-3-years/',
  },
];

const LogoSectionContentMobile: {
  id: number;
  icon: NextImageSrc;
  alt: string;
  width: number;
  height: number;
  link: string;
}[] = [
  {
    id: 1,
    icon: YOUR_STORY_LOGO,
    alt: 'YOUR_STORY_LOGO',
    width: 236,
    height: 92,
    link: 'https://yourstory.com/2019/10/expat-indian-cuisine-us-cumin-club-food-startup',
  },
  {
    id: 2,
    icon: BROWN_GIRL_MAGAZINE_LOGO,
    alt: 'BROWN_GIRL_MAGAZINE_LOGO',
    width: 190,
    height: 160,
    link: 'https://browngirlmagazine.com/if-youre-missing-homestyle-indian-food-cumin-club-should-your-weekly-meal-plan/',
  },
  {
    id: 3,
    icon: DELIVERY_RANK_LOGO,
    alt: 'DELIVERY_RANK_LOGO',
    width: 230,
    height: 55,
    link: 'https://www.deliveryrank.com/blog/cuminclub-interview',
  },
  {
    id: 4,
    icon: THE_BETTER_INDIA_LOGO,
    alt: 'THE_BETTER_INDIA_LOGO',
    width: 133,
    height: 133,
    link: 'https://www.thebetterindia.com/285841/nri-creates-healthy-vegetarian-five-minute-meal-kits-indian-food-in-us/',
  },
  {
    id: 5,
    icon: AVS_LOGO,
    alt: 'AVS_LOGO',
    width: 150,
    height: 150,
    link: 'https://www.avstv.com/cumin-club-celebrates-3-years/',
  },
  {
    id: 6,
    icon: YOUR_STORY_LOGO,
    alt: 'YOUR_STORY_LOGO',
    width: 236,
    height: 92,
    link: 'https://yourstory.com/2019/10/expat-indian-cuisine-us-cumin-club-food-startup',
  },
  {
    id: 7,
    icon: BROWN_GIRL_MAGAZINE_LOGO,
    alt: 'BROWN_GIRL_MAGAZINE_LOGO',
    width: 190,
    height: 160,
    link: 'https://browngirlmagazine.com/if-youre-missing-homestyle-indian-food-cumin-club-should-your-weekly-meal-plan/',
  },
  {
    id: 8,
    icon: DELIVERY_RANK_LOGO,
    alt: 'DELIVERY_RANK_LOGO',
    width: 230,
    height: 55,
    link: 'https://www.deliveryrank.com/blog/cuminclub-interview',
  },
  {
    id: 9,
    icon: THE_BETTER_INDIA_LOGO,
    alt: 'THE_BETTER_INDIA_LOGO',
    width: 133,
    height: 133,
    link: 'https://www.thebetterindia.com/285841/nri-creates-healthy-vegetarian-five-minute-meal-kits-indian-food-in-us/',
  },
  {
    id: 10,
    icon: AVS_LOGO,
    alt: 'AVS_LOGO',
    width: 150,
    height: 150,
    link: 'https://www.avstv.com/cumin-club-celebrates-3-years/',
  },
];

const MiddleSectionContent: CarouselType[] = [
  {
    id: '1',
    icon: GINGER_CHILLI,
    alt: 'GINGER_CHILLI',
    title: 'Cuisines of India, not Indian cuisine',
    subtitle:
      'All our dishes use high quality ingredients and spices specific to each region of India. You won’t catch us mixing our Kashmiri chillies with our byadagis!',
    //blurUrl: paneerButterMasalaBagBlur,
  },
  {
    id: '2',
    icon: CHEF_ASSET,
    alt: 'CHEF_ASSET',
    title: 'We bring true local flavor',
    subtitle:
      'Our team of chefs hail from all over India and bring their expertise into creating their regional dishes with that true authentic flavor.',
    //blurUrl: stainlessSteelPotBlur,
  },
  {
    id: '3',
    icon: PAV_BHAJI_PACKET,
    alt: 'PAV_BHAJI_PACKET',
    title: 'When we say clean food, we mean it',
    subtitle:
      'We don’t do preservatives, period. Our innovative freeze drying technology gives our meals a long shelf life and retains every ounce of flavor and nutrition. ',
    //blurUrl: hyderabadiBiryaniBlur,
  },
];

const OneLineDetailContent: TOneLineDeatil[] = [
  { id: 1, text: 'Over a million meals served' },
  { id: 2, text: 'Trusted by 40,000+ customers' },
  { id: 3, text: 'As seen on NBC and Bon Appétit' },
];

export const FeaturedInContent: {
  id: number;
  icon: NextImageSrc;
  alt: string;
  url: string;
}[] = [
  {
    id: 1,
    icon: BuzzFeedLogo,
    alt: 'Buzz Feed',
    url: 'https://www.buzzfeed.com/brittneytrinh/diwali-gift-ideas',
  },
  {
    id: 2,
    icon: NewYorkPostLogo,
    alt: 'NewYork Post',
    url: '',
  },
  {
    id: 3,
    icon: BonAppeetitLogo,
    alt: 'Bon Appeetit',
    url: '',
  },
  {
    id: 4,
    icon: WomenHealthLogo,
    alt: "Women's Health",
    url: 'https://www.womenshealthmag.com/weight-loss/a19982815/meal-delivery-boxes/',
  },
  {
    id: 5,
    icon: TheTakeoutLogo,
    alt: 'The Takeout',
    url: 'https://thetakeout.com/best-easy-weeknight-indian-food-cumin-club-instant-meal-1849794239',
  },
];

export const WhyJoinContent: {
  id: number;
  title: string;
  description: string;
  icon: NextImageSrc;
}[] = [
  {
    id: 1,
    title: ' Packed with rich flavor',
    description:
      'All of our dishes are curated by expert Indian chefs with real, regionally-authentic spices and flavors. The result: genuinely Indian, can’t-stop-eating delicious dinners.',
    icon: 'https://d222i9ppxs2fqe.cloudfront.net/why_join_club_img_1.png',
  },
  {
    id: 2,
    title: 'Clean, good-for-you food',
    description:
      'Our meals are freeze-dried to lock in flavor (like your boxed pasta or instant coffee) so we have no need for additives or preservatives. This also means our meals have a long shelf life and can be enjoyed on a whim from your pantry!',
    icon: 'https://d222i9ppxs2fqe.cloudfront.net/why_join_club_img_2.png',
  },
  {
    id: 3,
    title: 'Ready in just 5 minutes',
    description:
      'All of our 30+ dishes only take around 5 minutes to prepare and you only need hot water! It’s perfect for when you want something quick, healthy, and delicious.',
    icon: 'https://d222i9ppxs2fqe.cloudfront.net/why_join_club_img_3.png',
  },
];

export const HowToPrepageContent: {
  id: number;
  title: string;
  description: string;
  icon: NextImageSrc;
}[] = [
  {
    id: 1,
    title: 'Boil water',
    description: 'Boil the specified amount of water in a pot or saucepan. ',
    icon: howToPrepageImg1,
  },
  {
    id: 2,
    title: 'Add your pack',
    description: 'Add in the contents of your Cumin Club pack and stir.',
    icon: howToPrepageImg2,
  },
  {
    id: 3,
    title: 'Enjoy your meal!',
    description: 'Close the lid, let it sit for a few minutes and enjoy!',
    icon: howToPrepageImg3,
  },
];

export {
  carouselContent,
  TasteSectionContent,
  ReviewCarouselContent,
  posterContent,
  GiftSectionContent,
  LogoSectionContent,
  MiddleSectionContent,
  LogoSectionContentMobile,
  OneLineDetailContent,
};
