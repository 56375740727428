import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useReactiveVar } from '@apollo/client';

import { redirectIndiaModalOnesOpenVar } from 'store/utils';
import useAuth from '@utils/useAuth';
import ModalComponent from '@components/ModalComponent';
import Button from '@components/Button';
import { INDIA_BASE_URL } from '@utils/data';

import { useStyles } from './useStyles';
import { AppRoutes } from '@utils/appRoutes';

interface IRedirectToIndiaModal {
  isStepTwo?: boolean;
}

const RedirectToIndiaModal = ({ isStepTwo = false }: IRedirectToIndiaModal) => {
  const [openIndRedirectModal, setOpenIndRedirectModal] =
    useState<boolean>(false);
  const router = useRouter();

  const { detectedCountryCode, setCountryCode } = useAuth();

  const redirectIndiaModalOnesOpen = useReactiveVar(
    redirectIndiaModalOnesOpenVar
  );

  const classes = useStyles();

  useEffect(() => {
    if (detectedCountryCode === 'ind' && !redirectIndiaModalOnesOpen) {
      setOpenIndRedirectModal(true);
      redirectIndiaModalOnesOpenVar(true);
    }
  }, [detectedCountryCode, redirectIndiaModalOnesOpen]);

  const onHandleContinueToUsWeb = () => {
    setCountryCode('usa');
    setOpenIndRedirectModal(false);
    if (isStepTwo) {
      router.push(`/usa${AppRoutes.ORDER_STEP_TWO}`);
    }
  };

  const onHandleRedirectToIndWeb = () => {
    router.push(INDIA_BASE_URL);
  };

  return (
    <ModalComponent
      open={openIndRedirectModal}
      handleClose={() => {}}
      bgColorOpacity={1}
    >
      <Box className={classes.mainContainer}>
        {/* <Box className={classes.closeBtnWrapper}>
          <CloseIcon
            fontSize="medium"
            sx={{ color: 'cinnamon.main', cursor: 'pointer' }}
            onClick={handleClose}
          />
        </Box> */}
        <Typography variant="body1" color="cinnamon.main" align="center">
          It appears that you are browsing from India. Would you like to go to
          our India website?
        </Typography>

        <Box className={classes.btnWrapper}>
          <Button
            color="cinnamon"
            variant="contained"
            textColor="whitePepper.main"
            borderRadius="30"
            onClick={onHandleRedirectToIndWeb}
          >
            India Website
          </Button>

          <Button
            color="turmeric"
            variant="contained"
            textColor="garamMasala.main"
            width="fit-content"
            borderRadius="30"
            onClick={onHandleContinueToUsWeb}
          >
            Continue on the US Website
          </Button>
        </Box>
      </Box>
    </ModalComponent>
  );
};

export default RedirectToIndiaModal;
